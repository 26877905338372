import { fetchCompanyBankAccounts } from "apis/bankAccounts";
import { AvField, AvForm } from "availity-reactstrap-validation";
import Select from "react-select";
import React, { useEffect, useState } from "react";
import { Label } from "reactstrap";

export default function A3tmad(props) {
  const { t, setIsFirstStepValid, setPaymentPayload } = props;

  const [a3tmadBox, setA3tmadBox] = useState("حواله بنكيه");
  const a3tmadList = [
    "زين كاش العراق 620488",
    "سبايدر",
    "اليقين",
    "السعد",
    "سما",
    "الأفضل",
    "سوليتير",
    "لورنس",
    "وهاج",
    "الخوجا",
    "الاسطوره",
    "مرسال",
    "شمس",
  ];
  //   const a3tmadList = [
  //     "حواله بنكيه"
  //   ];

  useEffect(() => {
    if (a3tmadBox) {
      setIsFirstStepValid(true);
    }
  }, [a3tmadBox]);
  console.log("value payment", a3tmadBox);
  // useEffect(() => {
  //   setIsFirstStepValid(true);

  // }, []);

  return (
    <div className="my-3">
      <Select
        name="a3tmadBox"
        onChange={(e) => {
          setA3tmadBox(e.value);
          setPaymentPayload(e.value);
        }}
        required
        placeholder="حواله بنكيه"
        options={a3tmadList.map((a3tmad) => {
          return {
            label: a3tmad,
            value: a3tmad,
          };
        })}
      ></Select>
      {a3tmadBox && (
        <>
          <AvForm className="mt-5">
            <h5 className="mb-4">{t("Payment details")}</h5>
            <div>
              <Label>{t("اسم المستلم")}</Label>
              <AvField
                type="text"
                name="a3tmadBox"
                value="حسان ابراهيم"
                validate={{ required: true }}
                disabled={true}
              ></AvField>
            </div>
          </AvForm>

          <AvForm className="mt-3">
            <div>
              <Label>{t("حواله بنكيه")}</Label>
              <AvField
                type="text"
                name="a3tmadBox"
                value={a3tmadBox}
                validate={{ required: true }}
                disabled={true}
              ></AvField>
            </div>
          </AvForm>

          <AvForm className="mt-3">
            <div>
              <Label>{t("الواجهة")}</Label>
              <AvField
                type="text"
                name="a3tmadBox"
                value="تركيا - بورصه -  ميدان تشار شمبا"
                validate={{ required: true }}
                disabled={true}
              ></AvField>
            </div>
          </AvForm>
        </>
      )}
    </div>
  );
}
